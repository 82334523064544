import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const author = data.site.siteMetadata.author;
        return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                marginBottom: '0'
              }}
            >
                <Image
                  fixed={data.avatar.childImageSharp.fixed}
                  alt={author}
                  style={{
                    marginRight: '0.8rem',
                    marginBottom: 0,
                    minWidth: 50,
                    borderRadius: `100%`,
                    float: 'left',
                    width: '3.5rem',
                    height: '3.5rem',
                  }}
                />
                <div
                    display={'inline-block'}
                 >
                  Coder, Rider, Traveler.
                </div>
            </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/test3_2.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Bio
